<template>
  <v-card class="evaluations-tree">
    <v-card-title class="primary white--text px-2 py-2 text-h6" style="height: 50px">
      <v-row no-gutters align="center">
        Formulación de Evaluaciones
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="#adadb0"
              dark
              @click.prevent="handleCreate()"
              >Crear
            </v-btn>
          </template>
          <span>Crear Evaluación</span>
        </v-tooltip>
      </v-row>
    </v-card-title>
    <v-row class="mx-0" justify="space-between">
      <v-col md="5" cols="12" class="mt-2">
        <v-text-field
          v-model="search"
          label="Buscar"
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        />
        <v-treeview
          :key="indexDefault"
          :open-all="true"
          :active.sync="active"
          :items="items"
          :load-children="getChildrenEvaluations"
          :open.sync="open"
          :search="search"
          :filter="filter"
          dense
          shaped
          hoverable
          activatable
          :return-object="true"
          :color="
            item.evaluation_id === 0 ? 'light-blue darken-4' : 'light-blue darken-1'
          "
          selected-color="primary"
          open-on-click
          transition
          @update:active="getSelectedEvaluation($event)"
        >
          <template v-slot:prepend="{ item }">
            <v-avatar
              size="10"
              color="light-blue darken-4"
              v-if="item.evaluation_id === 0"
            />
            <v-avatar
              size="10"
              color="light-blue darken-1"
              v-if="item.evaluation_id !== 0"
            />
          </template>
          <template v-slot:append="{ item }">
            <v-menu offset-y class="options-menu" open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-1" icon x-small v-bind="attrs" v-on="on"
                  ><v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  class="px-1"
                  v-if="item.evaluation_id === 0 && item.has_children"
                  @click="handleWeighing(item)"
                >
                  <v-list-item-icon style="margin: 0px">
                    <v-btn class="ma-1" x-small inline icon fab color="primary"
                      ><v-icon>mdi-label-percent</v-icon></v-btn
                    >
                  </v-list-item-icon>
                  <v-list-item-content style="padding: 0px">
                    Asignar peso ponderado</v-list-item-content
                  >
                </v-list-item>
                <v-list-item class="px-1" @click="handleEdit(item)">
                  <v-list-item-icon style="margin: 0px">
                    <v-btn class="ma-1" x-small inline icon fab color="primary"
                      ><v-icon>mdi-pencil-outline</v-icon></v-btn
                    >
                  </v-list-item-icon>
                  <v-list-item-content style="padding: 0px">
                    Editar Evaluación
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-1" @click="deleteItem(item)" color="red">
                  <v-list-item-icon style="margin: 0px">
                    <v-btn class="ma-1" x-small inline fab color="red"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </v-list-item-icon>
                  <v-list-item-content style="padding: 0px">
                    Eliminar Evaluación
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-treeview>
      </v-col>

      <v-col md="7" cols="12" class="d-flex">
        <v-divider vertical />
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected.name"
            class="text-h6 grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            <v-card-text><h3>Seleccione una evaluación</h3> </v-card-text>
          </div>
          <v-card
            v-else-if="selected.evaluation_id !== 0"
            :key="selected.id"
            class="mx-auto neu-glow-inset"
          >
            <div v-if="selected.id" :key="selectedIndex">
              <v-card-text style="padding-top: 0px">
                <h2 class="my-2 text-center">
                  {{ selected.name }}
                </h2>
              </v-card-text>
              <v-subheader
                >Fecha de creación:
                {{ toLocaleDateString(selected.created_at.split('T')[0]) }}</v-subheader
              >
              <evaluation-requirements
                :key="selectedIndex + 1"
                class="my-4"
                :evaluation.sync="selected"
                @updateSelected="updateSelected()"
              />

              <evaluation-penalties
                :key="selectedIndex + 2"
                class="my-4"
                :evaluation.sync="selected"
                @updateSelected="updateSelected()"
              />
            </div>
            <div v-else><v-skeleton-loader type="article, actions" /></div>
          </v-card>
          <div
            v-else
            class="text-h6 grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            <v-card-text
              ><h3>Debe crear al menos una sección para esta evaluación</h3>
            </v-card-text>
          </div>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-row justify="center">
      <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
        <v-card class="pa-4" elevation="0">
          <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Evaluación</h2>
          <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Evaluación</h2>

          <span v-if="flow === 'edit' && item.created_at"
            >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
            {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
          >
          <v-form ref="formCompany" v-model="valid" style="width: 100%">
            <v-row align="baseline">
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-row style="margin: 0px" class="my-auto">
                  <label style="margin: 0px auto; font-size: 16px; width: 40%"
                    >Pertenece a:
                  </label>
                  <multiselect
                    style="width: 60%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="evaluation"
                    :options="evaluations"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="5" md="6">
                <v-text-field
                  :counter="100"
                  :rules="[rules.required, rules.max]"
                  label="Nombre"
                  v-model="name"
                />
              </v-col>
              <v-col cols="12" lg="3" md="6">
                <v-text-field
                  :rules="[rules.required]"
                  label="Duración"
                  v-model="duration"
                  suffix="día(s)"
                  step="1"
                  min="1"
                  type="number"
                />
              </v-col>
              <v-col cols="12" lg="3" md="6">
                <v-text-field
                  :rules="[rules.required]"
                  label="Peso ponderado"
                  v-model="weighing"
                  suffix="%"
                  step="1"
                  min="1"
                  type="number"
                />
              </v-col>
              <v-col cols="12" lg="3" md="6">
                <v-text-field
                  :rules="[rules.required]"
                  label="Resultado mínimo requerido"
                  v-model="score"
                  type="number"
                  @keypress="onlyNumber($event)"
                  step="1"
                  min="1"
                  :persistent-hint="flow === 'edit'"
                  :hint="scoreHint"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-col cols="12" style="margin-bottom: 20px; height: 10%">
          <div class="row">
            <v-btn
              style="margin: auto; margin-top: 10px"
              v-if="flow === 'create'"
              :disabled="!valid"
              color="primary"
              @click="createItem()"
            >
              Crear
            </v-btn>
            <v-btn
              style="margin: auto; margin-top: 10px"
              v-if="flow === 'edit'"
              :disabled="!valid"
              color="primary"
              @click="saveItem()"
            >
              Guardar
            </v-btn>

            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="reset"
            >
              Limpiar
            </v-btn>
            <v-btn
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="$modal.hide('crudModal')"
            >
              Cancelar
            </v-btn>
          </div>
        </v-col>
      </modal>
      <modal
        name="EvaluationWeighingModal"
        :height="'80%'"
        :width="'80%'"
        :adaptive="true"
      >
        <v-card class="pa-4" elevation="0">
          <h2>Evaluación: {{ item.name }}</h2>
          <v-divider horizotal class="my-4" />
          <h3 class="text-center">
            Seleccione el peso ponderado de cada sección de esta evaluación
          </h3>

          <v-form ref="form2" v-model="valid">
            <v-row
              class="mx-0 align-center"
              v-for="(section, index) in item.children"
              :key="index"
            >
              <v-col cols="7" sm="7" md="7" class="pl-10">
                <v-row no-gutters>
                  <v-card-text style="width: 10%" class="py-0 pr-2">
                    <h3 class="pr-2">{{ index + 1 }}</h3></v-card-text
                  >
                  <v-card-text style="width: 90%" class="py-0 pl-2">
                    {{ section.name }}</v-card-text
                  >
                </v-row>
              </v-col>
              <v-col cols="5" sm="5" md="5">
                <v-row no-gutters justify="center">
                  <span>{{ section.weighing }} %</span>
                </v-row>
                <v-slider
                  hide-details
                  v-model="section.weighing"
                  track-color="grey"
                  always-dirty
                  min="1"
                  max="100"
                  step="1"
                >
                  <template v-slot:prepend>
                    <v-icon color="primary" @click="section.weighing--">
                      mdi-minus
                    </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon color="primary" @click="section.weighing++">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-slider>
              </v-col>
              <hr />
            </v-row>
            <v-row class="mx-0"
              ><v-spacer />

              <v-btn color="primary" @click="saveWeighing()"> Guardar </v-btn
              ><v-spacer />
              <v-btn
                color="primary"
                class="ml-2"
                @click="$modal.hide('EvaluationWeighingModal')"
              >
                Cerrar </v-btn
              ><v-spacer
            /></v-row>
          </v-form>
        </v-card>
      </modal>
    </v-row>
  </v-card>
</template>
<script>
  import { toLocaleDateString } from '@/utils/helpers'
  import EvaluationRequirements from '@/components/Listing/SupervisionControl/EvaluationRequirements'
  import EvaluationPenalties from '@/components/SupervisionControl/EvaluationPenalties'

  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  export default {
    name: 'EvaluationsTree',
    components: { EvaluationRequirements, EvaluationPenalties },
    data() {
      return {
        indexDefault: 0,
        requerimentDialog: false,
        flow: '',
        selectedIndex: 1,
        active: [],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        evaluationsTree: [],
        evaluations: [],
        valid: true,
        validContent: true,
        open: [],
        item: {},
        selected: {},
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        search: null,
        caseSensitive: false,
        name: '',
        score: '',
        weighing: 100,
        duration: 1,
        evaluation: { id: 0, name: 'Ninguna asignación' },
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 100 : true) || 'Debe poser menos de 100 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      this.getDefaultData()
      this.getEvaluations()
      // this.checkActiveEvaluation()
    },
    watch: {
      selected(newValue) {
        this.selectedIndex++
      },
    },

    computed: {
      items() {
        return this.evaluationsTree
      },
      filter() {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
      },
      scoreHint() {
        if (this.item.total_score !== undefined) {
          const text = this.item.total_score > 0 ? ' puntos' : ' puntos'
          return (
            'Resultado máximo acumulado esperado según los puntos a evaluar: ' +
            this.item.total_score +
            text
          )
        } else {
          return ''
        }
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      onlyNumber($event) {
        const keyCode = $event.keyCode ? $event.keyCode : $event.which
        if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault()
        }
      },

      checkActiveEvaluation() {
        if (this.$route.query.evaluationId) {
          this.$axios
            .get('evaluations/' + this.$route.query.evaluationId + '?children=3')
            .then((response) => {
              this.active = [response.data]
              this.selected = this.active[0].children[0]
            })
        }
      },
      isURL(str) {
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },
      async getDefaultData() {
        let searchStr = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.search && this.search !== '') {
          searchStr = '&search=' + this.search
        }
        this.$axios
          .get('evaluations?evaluation_id=0&children=1' + searchStr + orderString)
          .then((response) => {
            this.evaluationsTree = response.data
            this.evaluationsTree.forEach((evaluation) => {
              if (
                this.$route.query.evaluationId &&
                parseInt(this.$route.query.evaluationId) === evaluation.id
              ) {
                this.active =
                  evaluation.children.length > 0 ? [evaluation.children[0]] : []
              }
              if (evaluation.children && evaluation.children.length === 0) {
                delete evaluation.children
              } else if (evaluation.children && evaluation.children.length > 0) {
                evaluation.children.forEach((evaluation2) => {
                  if (evaluation2.children && evaluation2.children.length === 0) {
                    delete evaluation2.children
                  } else if (evaluation2.children && evaluation2.children.length > 0) {
                    evaluation2.children.forEach((evaluation3) => {
                      if (evaluation3.children && evaluation3.children.length === 0) {
                        delete evaluation3.children
                      }
                    })
                  }
                })
              }
              this.indexDefault++
            })
          })
          .catch((err) => console.warn(err))
      },

      async getChildrenEvaluations(item) {
        if (item.has_children) {
          await pause(2000)
          return this.$axios
            .get('evaluations?children=1&evaluation_id=' + item.id)
            .then((response) => {
              item.children.push(...response.data)
            })
            .catch((err) => console.warn(err))
        }
      },
      getSelectedEvaluation(evaluation) {
        if (evaluation.length > 0) {
          this.$axios.get('evaluations/' + evaluation[0].id).then((response) => {
            this.selected = response.data
          })
        }
      },
      updateSelected() {
        this.$axios.get('evaluations/' + this.selected.id).then((response) => {
          this.selected = response.data
        })
      },
      async getEvaluations(search) {
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios.get('evaluations?limit=100' + searchStr).then((response) => {
          this.evaluations = []
          this.evaluations.push(...response.data)
        })
      },
      async saveWeighing() {
        this.item.children.forEach((section) => {
          this.$axios.patch('evaluations/' + section.id, {
            weighing: section.weighing,
          })
        })
        this.$modal.hide('EvaluationWeighingModal')
      },
      setLoadedRequirements() {
        this.loadedRequirements = this.evaluation.requirements.filter(
          (requirement) => parseInt(requirement.requirement_id) === 0
        )
        this.loadedRequirements.sort((a, b) => {
          if (a.description < b.description) {
            return -1
          } else if (a.description > b.description) {
            return +1
          } else {
            return 0
          }
        })
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getDefaultData()
      },
      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.score = ''
        this.duration = 1
        this.weighing = 100
        this.evaluation = { id: 0, name: 'Ninguna asignación' }

        this.$modal.show('crudModal')
      },

      handleWeighing(item) {
        this.item = item
        this.$modal.show('EvaluationWeighingModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.score = this.item.score
        this.duration = this.item.duration
        this.weighing = this.item.weighing
        this.evaluation = this.item.father
          ? this.item.father
          : { id: 0, name: 'Ninguna asignación' }
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },
      async createItem() {
        this.$axios
          .post('evaluations', {
            evaluation_id: this.evaluation.id,
            name: this.name,
            score: this.score,
            duration: this.duration,
            weighing: this.weighing,
          })
          .then(() => {
            this.getDefaultData()
            this.$modal.hide('crudModal')
          })
      },

      async saveItem() {
        this.$axios
          .patch('evaluations/' + this.item.id, {
            evaluation_id: this.evaluation.id,
            name: this.name,
            score: this.score,
            duration: this.duration,
            weighing: this.weighing,
          })
          .then(() => {
            this.getDefaultData()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text:
            'Eliminarás esta información y todas sus dependencias de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios.delete('evaluations/' + item.id).then(() => {
              this.getDefaultData()
              this.selected = {}
              this.$swal({
                title: '¡Listo!',
                text: 'La evaluación fue eliminada',
                icon: 'success',
              })
            })
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .v-application--is-ltr .v-treeview-node__content {
    margin-left: 0px;
    max-width: 92%;
  }
  .evaluations-tree {
    .v-treeview-node__root {
      padding: 0px;
      justify-content: flex-end;
    }
    .v-treeview-node__level {
      width: 20px;
    }
    .v-subheader {
      height: auto;
    }
    .v-treeview-node__content {
      align-items: center;
    }
    // .v-treeview-node__children {
    //   width: 99%;
    //   float: right;
    // }
  }
</style>
