<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <v-row no-gutters justify="space-between" align="center">
        <smart-breadcrumbs :items="navigationElements" />
        <help class="help--breadcrumb" :helpId="26" />
      </v-row>

      <evaluations-tree />
    </v-container>
  </div>
</template>

<script>
  import EvaluationsTree from '@/components/SupervisionControl/EvaluationsTree'

  export default {
    components: { EvaluationsTree },
    name: 'Evaluations',
    data() {
      return {
        navigationElements: [
          {
            text: 'Control y Supervisión',
            disabled: false,
            query: null,
            params: null,
            name: 'business/supervision-control/Dashboard',
            href: '/business/supervision-control/admin-panel',
          },
          {
            text: 'Formulación de Evaluaciones',
            disabled: true,
          },
        ],
      }
    },
  }
</script>
<style lang="scss"></style>
