<template>
  <v-row class="mx-0 px-2">
    <help class="help--text-left" :helpId="28" :xSmall="true" /><h4
      class="align-self-center"
      >Penalidades</h4
    >
    <v-btn icon class="ml-auto" style="float: right" @click="handleCreate()">
      <v-icon color="primary">add</v-icon>
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="penalties"
      :items-per-page="5"
      :sort-by="['description']"
      :sort-desc="[false]"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
    >
      <template v-slot:[`item.actions`]="{ item }" style="width: 100px">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-1" @click="handleEdit(item)">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click.prevent="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data
        ><div class="text-center" />
        Aún no hay penalidades
      </template>
      <template v-slot:no-results
        ><div class="text-center" />
        No hay resultados para su búsqueda
      </template>
    </v-data-table>
    <modal name="penaltyModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Penalidad</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Penalidad</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                :counter="100"
                :rules="[rules.required, rules.max]"
                label="Descripción"
                v-model="description"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                :counter="100"
                :rules="[rules.max]"
                label="Observación"
                v-model="observation"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                prefix="USD"
                label="Monto"
                v-model="amount"
                type="number"
                min="0"
                step="0.01"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model="level" :rules="[rules.required]">
                <template v-slot:label>
                  <div>Prioridad</div>
                </template>
                <v-radio v-for="item in levels" :key="item.id" :value="item.id">
                  <template v-slot:label>
                    {{ item.name }}
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-radio-group v-model="type" :rules="[rules.required]">
                <template v-slot:label>
                  <div>Tipo de penalidad</div>
                </template>
                <v-radio v-for="item in types" :key="item.id" :value="item.id">
                  <template v-slot:label>
                    {{ item.name }}
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn small
              style="margin: auto; margin-top: 10px" color="primary" @click="reset">
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('penaltyModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </v-row>
</template>

<script>
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'EvaluationPenalties',
    components: {},
    props: {
      evaluation: {
        type: Object,
        required: true,
        default: () => {
          return {}
        },
      },
    },
    data() {
      return {
        loading: true,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        headers: [
          { text: 'Descripción', value: 'description' },
          {
            text: 'Tipo',
            align: 'left',
            width: '200px',
            value: 'typeText',
          },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
        },
        description: '',
        type: 1,
        level: 1,
        observation: '',
        amount: 0,
        types: [
          { id: 1, name: 'Llamado de atención' },
          { id: 2, name: 'Indemnización' },
          { id: 3, name: 'Finaliación del contrato' },
        ],
        levels: [
          { id: 1, name: 'Baja' },
          { id: 2, name: 'Media' },
          { id: 3, name: 'Alta' },
          { id: 4, name: 'Grave' },
        ],
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          max: (v) =>
            v ? v.length <= 100 : true || 'Debe poser menos de 100 caracteres',
        },
      }
    },
    watch: {
      evaluation() {},
    },
    computed: {
      penalties() {
        if (this.evaluation.penalties && this.evaluation.penalties.length > 0) {
          const penalties = this.evaluation.penalties
          penalties.forEach((penalty) => {
            switch (penalty.type) {
              case 1:
                penalty.typeText = 'Llamado de atención'
                break
              case 2:
                penalty.typeText = 'Indemnización'
                break
              case 3:
                penalty.typeText = 'Finaliación del contrato'
                break
              default:
                penalty.typeText = ''
                break
            }
          })
          return penalties
        } else {
          return []
        }
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.description = ''
        this.amount = 0
        this.observation = ''
        this.type = 1
        this.level = 1

        this.$modal.show('penaltyModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.description = this.item.description
        this.amount = this.item.amount
        this.observation = this.item.observation
        this.type = this.item.type
        this.level = this.item.level
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('penaltyModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.validate()
        this.$axios
          .post('penalties', {
            evaluation_id: this.evaluation.id,
            description: this.description,
            observation: this.observation ? this.observation : null,
            type: this.type,
            level: this.level,
            amount: this.amount ? this.amount : 0,
          })
          .then(() => {
            this.page = 1
            this.$emit('updateSelected')
            this.$modal.hide('penaltyModal')
          })
      },

      async saveItem() {
        this.validate()
        this.$axios
          .patch('penalties/' + this.item.id, {
            evaluation_id: this.evaluation.id,
            description: this.description,
            observation: this.observation ? this.observation : null,
            type: this.type,
            level: this.level,
            amount: this.amount ? this.amount : 0,
          })
          .then(() => {
            this.page = 1
            this.$emit('updateSelected')
            this.$modal.hide('penaltyModal')
          })
      },

      async deleteItem(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text:
            'Eliminarás esta información y todas sus dependencias de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios.delete('penalties/' + item.id).then(() => {
              this.$emit('updateSelected')
              this.$modal.hide('penaltyModal')
              this.$swal({
                title: '¡Listo!',
                text: 'La penalidad fue eliminada',
                icon: 'success',
                timer: 2000,
              })
            })
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
