<template>
  <v-row class="mx-0 px-2">
    <help class="help--text-left" :helpId="27" :xSmall="true" />
    <h4 class="align-self-center">Puntos a evaluar</h4>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="ml-auto"
          style="float: right"
          @click="handleCreate()"
        >
          <v-icon color="primary">add</v-icon>
        </v-btn></template
      ><span>Crear punto</span></v-tooltip
    >
    <v-tooltip v-if="requirements.length > 0" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          style="float: right"
          @click="$modal.show('requirementWeighingModal')"
        >
          <v-icon color="primary">mdi-label-percent</v-icon>
        </v-btn></template
      ><span>Asignar peso ponderado</span></v-tooltip
    >

    <v-data-table
      :headers="headers"
      :items="requirements"
      :items-per-page="5"
      :sort-by="['description']"
      :sort-desc="[false]"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
    >
      <template v-slot:[`item.actions`]="{ item }" style="width: 100px">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-1" @click="handleEdit(item)">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click.prevent="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data
        ><div class="text-center" />
        Aún no hay puntos a evaluar
      </template>
      <template v-slot:no-results
        ><div class="text-center" />
        No hay resultados para su búsqueda
      </template>
    </v-data-table>
    <modal name="requirementModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" :loading="loading">
        <h2>Sección: {{ evaluation.name }}</h2>
        <v-divider horizotal class="my-4" />
        <h3 class="text-center" v-if="flow === 'create'">Crear punto a evaluar</h3>
        <h3 class="text-center" v-if="flow === 'edit'">Editar punto a evaluar</h3>

        <v-form ref="formCompany" v-model="valid">
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-if="flow === 'create' || (flow === 'edit' && type > 0)"
            >
              <v-radio-group v-model="type" :rules="[rules.required]">
                <template v-slot:label>
                  <div>Tipo de punto a evaluar</div>
                </template>
                <v-radio v-for="item in types" :key="item.id" :value="item.id">
                  <template v-slot:label>
                    {{ item.name }}
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <v-row class="mx-0">
                <v-col cols="12">
                  <v-textarea
                    :rules="[rules.required, rules.max]"
                    :counter="250"
                    v-model="description"
                    label="Descripción"
                    rows="1"
                    :auto-grow="true"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    :rules="[rules.max]"
                    :counter="250"
                    v-model="observation"
                    label="Observación"
                    rows="1"
                    :auto-grow="true"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" v-if="type === 4">
              <h3 class="text-center">
                Agregar aspecto<v-btn
                  class="ml-2"
                  icon
                  small
                  elevation="2"
                  @click="addRequirement()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h3>
              <!-- <TransitionGroup name="fade" class="trans-container"> -->
              <v-row
                class="mx-0 align-center"
                v-for="(element, index) in childrens"
                :key="index"
              >
                <v-col cols="11" md="8">
                  <v-textarea
                    :rules="[rules.required, rules.max]"
                    :counter="250"
                    v-model="element.description"
                    :label="'Aspecto #' + (index + 1)"
                    rows="1"
                    :auto-grow="true"
                  />
                </v-col>
                <v-col cols="11" md="3">
                  <label class="caption">Puntaje: {{ element.required_value }}</label>
                  <v-slider
                    v-model="element.required_value"
                    track-color="grey"
                    always-dirty
                    min="1"
                    max="10"
                  >
                    <template v-slot:prepend>
                      <v-icon color="primary" @click="element.required_value--">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="primary" @click="element.required_value++">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider>
                </v-col>
                <v-scroll-x-reverse-transition>
                  <v-btn
                    absolute
                    right
                    icon
                    @click="deleteRequeriment(element)"
                    v-show="childrens.length > 1"
                    ><v-icon color="primary"> mdi-delete </v-icon></v-btn
                  >
                </v-scroll-x-reverse-transition>
              </v-row>
              <!-- </TransitionGroup> -->
            </v-col>
            <v-col cols="12">
              <h3 class="text-center">
                Agregar Criterios
                <v-btn class="ml-2" icon small elevation="2" @click="addGuideline()">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </h3>
              <v-row
                class="mx-0 align-center"
                v-for="(element, index) in guidelines"
                :key="index"
              >
                <v-col cols="11" md="8">
                  <v-textarea
                    rows="1"
                    :auto-grow="true"
                    :counter="250"
                    :rules="[rules.required, rules.max]"
                    label="Descripción"
                    v-model="element.description"
                  />
                </v-col>
                <v-col cols="11" md="3">
                  <v-text-field
                    :rules="[rules.required]"
                    label="Valor"
                    v-model="element.value"
                    step="1"
                    min="1"
                    :max="maxValue"
                    type="number"
                    @keypress="onlyNumber($event)"
                  />
                </v-col>
                <v-scroll-x-reverse-transition>
                  <v-btn
                    absolute
                    right
                    icon
                    @click="deleteGuideline(element)"
                    v-show="guidelines.length > 1"
                    ><v-icon color="primary"> mdi-delete </v-icon></v-btn
                  >
                </v-scroll-x-reverse-transition>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mx-0"
            ><v-spacer />
            <v-btn
              color="primary"
              v-if="flow === 'create'"
              :disabled="!valid"
              @click="createItem()"
            >
              Crear
            </v-btn>
            <v-btn
              color="primary"
              v-if="flow === 'edit'"
              :disabled="!valid"
              @click="saveItem()"
            >
              Guardar </v-btn
            ><v-spacer />
            <v-btn color="primary" class="ml-2" @click="$modal.hide('requirementModal')">
              Cerrar </v-btn
            ><v-spacer
          /></v-row>
        </v-form>
      </v-card>
    </modal>
    <modal
      name="requirementWeighingModal"
      :height="'80%'"
      :width="'80%'"
      :adaptive="true"
    >
      <v-card class="pa-4" elevation="0">
        <h2>Sección: {{ evaluation.name }}</h2>
        <v-divider horizotal class="my-4" />
        <h3 class="text-center">Seleccione el peso ponderado de cada punto a evaluar</h3>

        <v-form ref="form2" v-model="valid">
          <v-row
            class="mx-0 align-center"
            v-for="(item, index) in loadedRequirements"
            :key="index"
          >
            <v-col cols="7" class="pl-10">
              <v-row no-gutters>
                <v-card-text style="width: 10%" class="py-0 pr-2">
                  <h3 class="pr-2">{{ index + 1 }}</h3></v-card-text
                >
                <v-card-text style="width: 90%" class="py-0 pl-2">
                  {{ item.description }}</v-card-text
                >
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-row no-gutters justify="center">
                <span>{{ item.weighing }} %</span>
              </v-row>
              <v-slider
                hide-details
                v-model="item.weighing"
                track-color="grey"
                always-dirty
                min="1"
                max="100"
                step="1"
              >
                <template v-slot:prepend>
                  <v-icon color="primary" @click="item.weighing--"> mdi-minus </v-icon>
                </template>

                <template v-slot:append>
                  <v-icon color="primary" @click="item.weighing++"> mdi-plus </v-icon>
                </template>
              </v-slider>
            </v-col>
            <hr />
          </v-row>
          <v-row class="mx-0"
            ><v-spacer />

            <v-btn color="primary" @click="saveWeighing()"> Guardar </v-btn><v-spacer />
            <v-btn
              color="primary"
              class="ml-2"
              @click="$modal.hide('requirementWeighingModal')"
            >
              Cerrar </v-btn
            ><v-spacer
          /></v-row>
        </v-form>
      </v-card>
    </modal>
  </v-row>
</template>

<script>
  export default {
    name: 'EvaluationRequirements',
    components: {},
    props: {
      evaluation: {
        type: Object,
        required: true,
        default: () => {
          return {}
        },
      },
    },
    data() {
      return {
        flow: '',
        valid: true,
        loading: false,
        dialog: false,
        headers: [
          {
            text: 'Descripción',
            align: 'left',
            value: 'description',
          },
          {
            text: 'Tipo',
            align: 'left',
            width: '130px',
            value: 'typeText',
          },
          { text: 'Acciones', value: 'actions', width: '100px', sortable: false },
        ],
        page: 1,
        pages: 1,
        totalItems: 0,
        itemsPerPage: 10,
        item: {},
        loadingText: 'Cargando procesos',
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        requiredValue: 10,
        loadedRequirements: [],
        description: '',
        observation: '',
        type: 1,
        childrens: [],
        guidelines: [
          {
            description: '',
            value: null,
          },
        ],
        types: [
          { id: 1, name: 'Si / No' },
          { id: 2, name: 'Valor 1 a 5' },
          { id: 3, name: 'Valor 1 a 10' },
          { id: 4, name: 'Selección Simple' },
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          max: (v) =>
            (v ? v.length <= 250 : true) || 'Debe poser menos de 250 caracteres',
        },
      }
    },
    watch: {
      evaluation(newValue) {
        newValue.requirements.sort((a, b) => {
          if (a.description < b.description) {
            return +1
          } else if (a.description > b.description) {
            return -1
          } else {
            return 0
          }
        })
        this.setLoadedRequirements()
      },
      type(newValue) {
        if (newValue === 4 && this.childrens.length === 0) {
          this.childrens = [
            {
              evaluation_id: this.evaluation.id,
              required_value: 1,
              description: '',
              type: 0,
            },
          ]
        }
      },
    },
    created() {
      this.setLoadedRequirements()
    },
    computed: {
      maxValue() {
        switch (this.item.type) {
          case 2:
            return 5
          case 3:
            return 10
          default:
            return 0
        }
      },
      requirements() {
        if (this.evaluation.requirements && this.evaluation.requirements.length > 0) {
          const requirements = this.evaluation.requirements.filter(
            (requirement) => parseInt(requirement.requirement_id) === 0
          )
          requirements.forEach((requirement) => {
            switch (requirement.type) {
              case 1:
                requirement.typeText = 'Si / No'
                break
              case 2:
                requirement.typeText = 'Valor 1 a 5'
                break
              case 3:
                requirement.typeText = 'Valor 1 a 10'
                break
              case 4:
                requirement.typeText = 'Selección'
                break
              default:
                requirement.typeText = 'N/A'
                break
            }
          })
          requirements.sort((a, b) => {
            if (a.description < b.description) {
              return +1
            } else if (a.description > b.description) {
              return -1
            } else {
              return 0
            }
          })
          return requirements
        } else {
          return []
        }
      },
    },
    methods: {
      setLoadedRequirements() {
        this.loadedRequirements = this.evaluation.requirements.filter(
          (requirement) => parseInt(requirement.requirement_id) === 0
        )
        this.loadedRequirements.sort((a, b) => {
          if (a.description < b.description) {
            return -1
          } else if (a.description > b.description) {
            return +1
          } else {
            return 0
          }
        })
      },
      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.description = ''
        this.observation = ''
        this.requiredValue = 10
        this.type = 1
        this.childrens = []
        this.guidelines = [
          {
            description: '',
            value: null,
          },
        ]
        this.valid = true

        this.$modal.show('requirementModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.description = this.item.description
        this.observation = this.item.observation
        this.requiredValue = this.item.type === 2 ? 5 : 10
        this.type = this.item.type
        this.childrens = this.item.childrens
        this.guidelines = this.item.guidelines

        this.$modal.show('requirementModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.loading = true
        this.validate()
        const data = {
          evaluation_id: this.evaluation.id,
          requirement_id: 0,
          description: this.description,
          required_value: this.type === 2 ? 5 : 10,
          type: this.type,
        }
        if (this.observation) {
          data.observation = this.observation
        }
        if (this.type === 4 && this.childrens.length > 0) {
          data.childrens = this.childrens
        }

        if (this.guidelines.length > 0) {
          data.guidelines = this.guidelines
        }
        this.$axios.post('requirements', data).then(() => {
          this.loading = false
          this.$emit('updateSelected')
          this.$modal.hide('requirementModal')
        })
      },

      async saveItem() {
        this.validate()
        this.loading = true
        const data = {
          evaluation_id: this.evaluation.id,
          requirement_id: 0,
          description: this.description,
          required_value: this.type === 2 ? 5 : 10,
          type: this.type,
        }
        if (this.observation) {
          data.observation = this.observation
        }
        if (this.type === 4 && this.childrens.length > 0) {
          data.childrens = this.childrens
        }
        if (this.guidelines.length > 0) {
          data.guidelines = this.guidelines
        }
        this.$axios.patch('requirements/' + this.item.id, data).then(() => {
          this.loading = false
          this.$emit('updateSelected')
          this.$modal.hide('requirementModal')
        })
      },

      async saveWeighing() {
        this.loadedRequirements.forEach((element) => {
          this.$axios.patch('requirements/' + element.id, { weighing: element.weighing })
        })
        this.$modal.hide('requirementWeighingModal')
      },

      async deleteItem(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text:
            'Eliminarás esta información y todas sus dependencias de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios.delete('requirements/' + item.id).then(() => {
              this.$emit('updateSelected')
              this.$modal.hide('requirementModal')
              this.$swal({
                title: '¡Listo!',
                text: 'La punto fue eliminado',
                icon: 'success',
                timer: 2000,
              })
            })
          }
        })
      },

      addRequirement() {
        this.childrens.push({
          evaluation_id: this.evaluation.id,
          required_value: 1,
          description: '',
          type: 0,
        })
      },
      deleteRequeriment(element) {
        this.childrens = this.childrens.filter((item) => item !== element)
      },
      addGuideline() {
        this.guidelines.push({
          description: '',
          value: null,
        })
      },
      deleteGuideline(element) {
        this.guidelines = this.guidelines.filter((item) => item !== element)
      },

      onlyNumber($event) {
        const keyCode = $event.keyCode ? $event.keyCode : $event.which
        if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault()
        }
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';

  // LIST ANIMATION START

  .trans-container {
    position: relative;
    padding: 0;
  }
  /* 1. declare transition */
  .fade-move,
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  }
  /* 2. declare enter from and leave to state */
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
  }
  /* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
  .fade-leave-active {
    position: absolute;
  }
  // LIST ANIMATION END
</style>
